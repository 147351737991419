<template>
  <div class="assignData">
    <el-button size="small" type="info" @click="showDialog">分配数据</el-button>
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <span slot="title"> 分配数据 </span>
      <el-form :model="assignForm" label-width="110px" :rules="assignRules" ref="assignFormRef">
        <el-form-item label="请选择负责人" prop="ownerId">
          <el-select v-model="assignForm.ownerId" placeholder="请选择" style="width: 300px" clearable size="small">
            <el-option v-for="{ id, accountName } in childList" :key="id" :value="id" :label="accountName"></el-option>
          </el-select>
          <p class="tipFont">分配给负责人操作所选择数据上传刀版文件、编辑刀版文件的权限</p>
        </el-form-item>
      </el-form>
      <CommonTable height="auto" max-height="400px" :selection="false" :cols="tableCol" :infoData="tableData">
        <template #mergeSlot="{ scoped }">
          <div class="merge-slot-class">
            <el-image style="width: 60px; height: 60px" :src="figure(scoped)" fit="contain"></el-image>
            <div>
              <div style="max-width: 200px">
                {{ scoped.name || '暂无名字' }}
              </div>
            </div>
          </div>
        </template>
        <template #CategorySlot="{ scoped }">
          {{ (scoped.productCategory && scoped.productCategory.name) || '暂无' }}
        </template>
        <template #structSlot="{ scoped: { styleList } }">
          <div v-if="!styleList.length">
            {{ '暂无款式' }}
          </div>
          <div v-else-if="styleList.length === 1">
            <el-tag>{{ styleList[0].name || '暂无款式名' }}</el-tag>
          </div>
          <div v-else>
            <el-popover
              placement="bottom"
              width="200"
              trigger="hover"
              :visible-arrow="false"
              popper-class="assignData-style"
            >
              <div>
                <p class="assignData-style-header">
                  <span class="assignData-style-header-font"> 全部款式</span>
                </p>
                <div class="assignData-style-tag-concent">
                  <el-tag v-for="item in styleList" :key="item.id" style="margin-right: 5px; margin-bottom: 5px">
                    {{ item.name || '暂无款式名' }}
                  </el-tag>
                </div>
              </div>
              <div slot="reference">
                <el-tag>{{ styleList[0].name || '暂无款式名' }}</el-tag>
                <i class="el-icon-arrow-down" style="margin-left: 5px; display: inline-block"></i>
              </div>
            </el-popover>
          </div>
        </template>
        <template #sizesSlot="{ scoped: { $sizeList } }">
          <div v-if="!$sizeList.length">
            {{ '暂无款式' }}
          </div>
          <div v-else-if="$sizeList.length === 1">
            <el-tag>{{ $sizeList[0].sizeName || '暂无尺码' }}</el-tag>
          </div>
          <div v-else>
            <el-popover
              placement="bottom"
              width="200"
              trigger="hover"
              :visible-arrow="false"
              popper-class="assignData-style"
            >
              <div>
                <p class="assignData-style-header">
                  <span class="assignData-style-header-font"> 全部尺码</span>
                </p>
                <div class="assignData-style-tag-concent">
                  <el-tag v-for="item in $sizeList" :key="item.id" style="margin-right: 5px; margin-bottom: 5px">
                    {{ item.sizeName || '暂无尺码' }}
                  </el-tag>
                </div>
              </div>
              <div slot="reference">
                <el-tag>{{ $sizeList[0].sizeName || '暂无尺码' }}</el-tag>
                <i class="el-icon-arrow-down" style="margin-left: 5px; display: inline-block"></i>
              </div>
            </el-popover>
          </div>
        </template>
        <template #operationSlot="{ scoped: { row } }">
          <ColorTextBtn @click="asignOperation(row)">{{ row.operation ? '取消选择' : '选择' }}</ColorTextBtn>
        </template>
      </CommonTable>
      <PaginationBar
        :size="size"
        :page="page"
        :total="selectionData.length"
        @refreshTableEventFun="handlePageChange"
      ></PaginationBar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAssignForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/components/avue/utils/util'
import { tableCol } from './tableCols.js'
import { childList, assignData } from '../api.js'
import { getValueFromObj, parseImgSrc } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      assignForm: {
        ownerId: ''
      },
      assignRules: {
        ownerId: [{ required: true, message: '请选择负责人', trigger: 'change' }]
      },
      tableCol,
      size: 10,
      page: 1,
      selectionData: [],
      childList: []
    }
  },
  computed: {
    ...mapGetters(['isMain']),
    // 拿到表格数据并进行处理
    tableData() {
      return this.selectionData.slice((this.page - 1) * this.size, this.page * this.size)
    },
    // 图片的路径如果没有就默认路径
    figure() {
      return (row) => {
        let src = getValueFromObj(row, 'styleList[0].styleDisplayImageList[0].displayImagePath')
        return src ? parseImgSrc(src) : require('@/assets/images/default.png')
      }
    }
  },
  watch: {
    // 当关闭窗口的时候重置校验
    dialogVisible: {
      handler(newVal) {
        if (newVal === false) {
          this.$refs['assignFormRef'].resetFields()
        }
      }
    },
    data: {
      handler(newVal) {
        const newArr = []
        newVal.forEach((item) => {
          let isFind = newArr.find((cell) => cell.id === item.id)
          if (!isFind) {
            newArr.push(item)
          }
        })
        const newListData = deepClone(newArr)
        newListData.map((item) => {
          item.operation = true
        })
        this.selectionData = newListData
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 点击分配数据按钮操作
    showDialog: _.throttle(function () {
      if (!this.data.length) {
        this.$message.warning('请先选择要分配的数据')
        return
      }
      this.dialogVisible = true
    }, 3000),
    // 点击提交的步骤
    submitAssignForm() {
      this.$refs['assignFormRef'].validate((valid) => {
        if (valid) {
          this.assignData()
        } else {
          return false
        }
      })
    },
    asignOperation(row) {
      row.operation = !row.operation
    },
    // 点击提交确定后某个步骤的操作
    assignDataHandler() {
      const choiceData = this.selectionData.filter((item) => {
        return item.operation === true
      })
      // 最终选中的数据
      // console.log('choiceData', choiceData)
      const idList = choiceData.map(({ id }) => id)
      // console.log('idList', idList)
      return idList
    },
    handlePageChange({ page, size }) {
      this.size = size
      this.page = page
    },
    // 获取子账号
    async getChildList() {
      if (this.isMain) {
        const { detail } = await childList()
        this.childList = detail
        this.$emit('getChildList', this.childList)
      }
    },
    // 分配数据
    async assignData() {
      const productPrototypeIdList = this.assignDataHandler()
      if (!productPrototypeIdList.length) {
        this.$message.warning('请先选择数据')
        return
      }
      const userId = this.assignForm.ownerId
      const query = { productPrototypeIdList, userId }
      const { code } = await assignData(query)
      if (code === 0) {
        this.$message.success('分配数据成功')
        this.dialogVisible = false
        this.sup_this.init()
      } else {
        this.$message.error('分配数据失败')
      }
    }
  },
  created() {
    this.getChildList()
  }
}
</script>

<style scoped lang="scss">
.assignData {
  display: inline-block;
  .el-dialog {
    display: block;
    .tipFont {
      margin: 0;
      padding: 0;
      color: #595961;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
    }
  }
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #dcdee0;
    color: #333;
  }
  .merge-slot-class {
    position: relative;
    display: flex;
    align-items: center;
    .el-image {
      margin-right: 10px;
      background: $border-color;
    }
  }
}
::v-deep .assignData-style-header {
  margin: 0;
  border-bottom: 1px solid #dcdee0;
  .assignData-style-header-font {
    display: inline-block;
    color: $color-primary;
    border-bottom: 1px solid $color-primary;
    padding: 10px;
  }
}
::v-deep .assignData-style-tag-concent {
  padding: 10px;
}
</style>
<style lang="scss">
.assignData-style {
  padding: 0;
}
</style>
