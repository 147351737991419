var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assignData"},[_c('el-button',{attrs:{"size":"small","type":"info"},on:{"click":_vm.showDialog}},[_vm._v("分配数据")]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"50%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" 分配数据 ")]),_c('el-form',{ref:"assignFormRef",attrs:{"model":_vm.assignForm,"label-width":"110px","rules":_vm.assignRules}},[_c('el-form-item',{attrs:{"label":"请选择负责人","prop":"ownerId"}},[_c('el-select',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请选择","clearable":"","size":"small"},model:{value:(_vm.assignForm.ownerId),callback:function ($$v) {_vm.$set(_vm.assignForm, "ownerId", $$v)},expression:"assignForm.ownerId"}},_vm._l((_vm.childList),function(ref){
var id = ref.id;
var accountName = ref.accountName;
return _c('el-option',{key:id,attrs:{"value":id,"label":accountName}})}),1),_c('p',{staticClass:"tipFont"},[_vm._v("分配给负责人操作所选择数据上传刀版文件、编辑刀版文件的权限")])],1)],1),_c('CommonTable',{attrs:{"height":"auto","max-height":"400px","selection":false,"cols":_vm.tableCol,"infoData":_vm.tableData},scopedSlots:_vm._u([{key:"mergeSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('div',{staticClass:"merge-slot-class"},[_c('el-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":_vm.figure(scoped),"fit":"contain"}}),_c('div',[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(scoped.name || '暂无名字')+" ")])])],1)]}},{key:"CategorySlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s((scoped.productCategory && scoped.productCategory.name) || '暂无')+" ")]}},{key:"structSlot",fn:function(ref){
var styleList = ref.scoped.styleList;
return [(!styleList.length)?_c('div',[_vm._v(" "+_vm._s('暂无款式')+" ")]):(styleList.length === 1)?_c('div',[_c('el-tag',[_vm._v(_vm._s(styleList[0].name || '暂无款式名'))])],1):_c('div',[_c('el-popover',{attrs:{"placement":"bottom","width":"200","trigger":"hover","visible-arrow":false,"popper-class":"assignData-style"}},[_c('div',[_c('p',{staticClass:"assignData-style-header"},[_c('span',{staticClass:"assignData-style-header-font"},[_vm._v(" 全部款式")])]),_c('div',{staticClass:"assignData-style-tag-concent"},_vm._l((styleList),function(item){return _c('el-tag',{key:item.id,staticStyle:{"margin-right":"5px","margin-bottom":"5px"}},[_vm._v(" "+_vm._s(item.name || '暂无款式名')+" ")])}),1)]),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-tag',[_vm._v(_vm._s(styleList[0].name || '暂无款式名'))]),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"margin-left":"5px","display":"inline-block"}})],1)])],1)]}},{key:"sizesSlot",fn:function(ref){
var $sizeList = ref.scoped.$sizeList;
return [(!$sizeList.length)?_c('div',[_vm._v(" "+_vm._s('暂无款式')+" ")]):($sizeList.length === 1)?_c('div',[_c('el-tag',[_vm._v(_vm._s($sizeList[0].sizeName || '暂无尺码'))])],1):_c('div',[_c('el-popover',{attrs:{"placement":"bottom","width":"200","trigger":"hover","visible-arrow":false,"popper-class":"assignData-style"}},[_c('div',[_c('p',{staticClass:"assignData-style-header"},[_c('span',{staticClass:"assignData-style-header-font"},[_vm._v(" 全部尺码")])]),_c('div',{staticClass:"assignData-style-tag-concent"},_vm._l(($sizeList),function(item){return _c('el-tag',{key:item.id,staticStyle:{"margin-right":"5px","margin-bottom":"5px"}},[_vm._v(" "+_vm._s(item.sizeName || '暂无尺码')+" ")])}),1)]),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-tag',[_vm._v(_vm._s($sizeList[0].sizeName || '暂无尺码'))]),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"margin-left":"5px","display":"inline-block"}})],1)])],1)]}},{key:"operationSlot",fn:function(ref){
var row = ref.scoped.row;
return [_c('ColorTextBtn',{on:{"click":function($event){return _vm.asignOperation(row)}}},[_vm._v(_vm._s(row.operation ? '取消选择' : '选择'))])]}}])}),_c('PaginationBar',{attrs:{"size":_vm.size,"page":_vm.page,"total":_vm.selectionData.length},on:{"refreshTableEventFun":_vm.handlePageChange}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitAssignForm}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }